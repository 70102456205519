.modTitleTop {
	background: url(../img/top/main_bg1_pc.jpg) center bottom repeat-x;
}

.modTitleTop .TitleTopSp {
	display: none;
}

.modTitleTop .modTitleTopIn {
	position: relative;
	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
}

.modTitleTop .modTitleTopIn .modTitleTopImg {
	position: absolute;
	right: -194px;
	bottom: 0;
}

.modTitleTop .modTitleTopIn .textArea {
	padding-bottom: 100px;
}

.modTitleTop .title {
	padding-top: 103px;
	margin-bottom: 20px;
}

.modTitleTop .text1 {
	font-weight: bold;
	font-size: 24px;
	font-size: 2.4rem;
	margin-bottom: 20px;
}

.modTitleTop .text2 {
	font-weight: bold;
	font-size: 14px;
	font-size: 1.4rem;
	margin-bottom: 20px;
	line-height: 2;
}

.myTop1 .box01 {
	background: url(../img/top/top_bg1_pc.jpg) center center no-repeat;
	background-size: cover;
	color: #fff;
	padding: 65px 55px 60px;
	margin-bottom: 20px;
}

.myTop1 .box01 .modTitle02 {
	margin-bottom: 25px;
}

.myTop1 .box01 .text01 {
	font-size: 14px;
	font-size: 1.4rem;
	margin-bottom: 30px;
}

.myTop1 .box01 .modBtn01_inline {
	display: block;
	font-size: 16px;
	font-size: 1.6rem;
	padding-left: 30px;
	padding-right: 30px;
}

.myTop1 .list {
	margin-bottom: 40px;
	display: flex;
	justify-content: space-between;
}

.myTop1 .list li {
	background: #e5f4ff;
}

.myTop1 .list .textArea {
	padding: 25px;
}

.myTop1 .list .textArea .j_match {
	margin-bottom: 25px;
}

.myTop1 .list .textArea .inText01 {
	border-bottom: 1px solid #fff;
	text-align: center;
	font-weight: bold;
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-size: 18px;
	font-size: 1.8rem;
	color: #00376a;
}

.myTop1 .list .textArea .inText01 .color01 {
	display: block;
	font-size: 12px;
	font-size: 1.2rem;
	color: #58a1d7;
}

.myTop1 .list .textArea .inText02 {
	font-size: 14px;
	font-size: 1.4rem;
}

.myTop1 .list .textArea .modBtn01 {
	font-size: 15px;
	font-size: 1.5rem;
}

.myTop1 .list.modColumnPc_3n li {
	width: 32%;
	margin-left: 2%;
}

.myTop1 .list.modColumnPc_3n li:nth-child(3n+1) {
	margin-left: 0;
}

.myTop2 .myTop2In {
	background: #e5f4ff;
	padding: 50px 60px;
	margin-bottom: 20px;
}

.myTop2 .myTop2In .text01 {
	margin-bottom: 30px;
}

.myTop2 .myTop2In .list {
	overflow: hidden;
}

.myTop2 .myTop2In .list li {
	float: left;
	width: 32%;
	margin-bottom: 20px;
}

.myTop2 .myTop2In .list li:nth-child(3) {
	clear: both;
	margin-right: 2%;
}

.myTop2 .myTop2In .list li:nth-child(5) {
	float: right;
}

.myTop2 .myTop2In .list li a {
	text-align: center;
	text-decoration: none;
	display: block;
	background: #fff;
	-moz-border-radius: 7px;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	padding: 30px;
}

.myTop2 .myTop2In .list li a .cell.imageArea {
	margin-bottom: 20px;
}

.myTop2 .myTop2In .list li a .cell.imageArea img {
	width: 110px;
}

.myTop2 .myTop2In .list li a .cell.textArea {
	background: url(../img/top/btn_bg.png) right bottom no-repeat;
	background-size: 19px auto;
	border-bottom: 2px solid #00376a;
	font-weight: bold;
	text-align: center;
	font-size: 20px;
	font-size: 2rem;
	padding-bottom: 30px;
}

.myTop2 .myTop2In .list li a .cell.textArea .inText01 {
	display: block;
	font-size: 14px;
	font-size: 1.4rem;
}

.myTop2 .myTop2In .list li.liType01 {
	width: 49%;
}

.myTop2 .myTop2In .list li.liType01 a {
	display: table;
	width: 100%;
}

.myTop2 .myTop2In .list li.liType01 a .cell {
	display: table-cell;
	vertical-align: bottom;
}

.myTop2 .myTop2In .list li.liType01 a .cell.imageArea {
	text-align: left;
	margin-bottom: 0;
	width: 140px;
}

.myTop2 .myTop2In .list li.liType01 a .cell.imageArea img {
	width: 130px;
}

.myTop2 .myTop2In .list li.liType01 a .cell.textArea {
	padding-bottom: 50px;
}

.myTop2 .myTop2In .list li:nth-child(2) {
	float: right;
}

.myTop2 .topBtn {
	text-align: center;
}

.myTop3 .modImgBlock_txt {
	width: 53%;
	font-size: 14px;
	font-size: 1.4rem;
}

.myTop3 .modImgBlock_txt .text01 {
	margin-bottom: 50px;
}

.myTop3 .modImgBlock_image {
	width: 43%;
}

.error404_block {
	height: 50vh;
}

.error-ttl {
	font-size: 400%;
}

@media only screen and (min-width: 993px) {

.myTop1 .box01 .linklist {
	display: flex;
	justify-content: space-between;
}

.myTop1 .box01 .linklist .link {
	flex-grow: 1;
}

.myTop1 .box01 .linklist .link:not(:last-child) {
	margin-right: 18px;
}

}

@media only screen and (max-width: 992px) {

.modTitleTop {
	background: none;
}

.modTitleTop .textArea {
	padding: 20px;
}

.modTitleTop .modTitleTopIn {
	height: auto;
	padding-right: 0;
	padding-left: 0;
}

.modTitleTop .modTitleTopIn .textWrap {
	background: url(../img/top/main_bg1_pc.jpg) center bottom repeat-x #fff;
	background-size: auto 100%;
	overflow: hidden;
}

.modTitleTop .modTitleTopIn .textWrap .textWrapIn {
	height: 600px;
	background: url(../img/top/main_bg2_sp.jpg) left bottom no-repeat;
	background-size: auto 100%;
	overflow: hidden;
	padding-right: 20px;
	padding-left: 20px;
}

.modTitleTop .title {
	padding-top: 30px;
	margin-bottom: 15px;
	overflow: hidden;
}

.modTitleTop .title img {
	float: right;
	width: 68%;
	max-width: 441px;
}

.modTitleTop .text1 {
	margin-bottom: 0;
	clear: both;
	float: right;
	text-align: right;
}

.myTop1 .box01 {
	background: url(../img/top/top_bg1_sp.jpg) center center no-repeat;
	background-size: cover;
	padding: 20px 30px 30px;
}

.myTop1 .box01 .modBtn01_inline {
	width: auto;
	max-width: 382px;
	margin: 0 auto;
}

.myTop1 .box01 .linklist .link:not(:last-child) {
	margin-bottom: 18px;
}

.myTop1 .list {
	flex-direction: column;
}

.myTop1 .list .textArea .modBtn01 {
	width: auto;
	max-width: 382px;
	margin: 0 auto;
}

.myTop1 .list.modColumnPc_3n li {
	width: auto;
	margin-left: 0;
}

.myTop1 .list.modColumnPc_3n li + li {
	margin-top: 20px;
}

.myTop2 .myTop2In {
	padding: 30px 20px;
}

.myTop2 .myTop2In .list li {
	float: none;
	width: auto;
	word-break: break-all;
}

.myTop2 .myTop2In .list li:nth-child(3) {
	margin-right: 0;
}

.myTop2 .myTop2In .list li:nth-child(5) {
	float: none;
}

.myTop2 .myTop2In .list li a {
	display: table;
	width: 100%;
	padding: 20px 10px;
}

.myTop2 .myTop2In .list li a .cell {
	display: table-cell;
	vertical-align: middle;
	line-height: 1.3;
}

.myTop2 .myTop2In .list li a .cell.imageArea {
	width: 92px;
	margin-bottom: 0;
}

.myTop2 .myTop2In .list li a .cell.imageArea img {
	width: 92px;
}

.myTop2 .myTop2In .list li a .cell.textArea {
	margin-right: 10px;
}

.myTop2 .myTop2In .list li.liType01 {
	width: 100%;
}

.myTop2 .myTop2In .list li.liType01 a .cell {
	vertical-align: middle;
}

.myTop2 .myTop2In .list li.liType01 a .cell.imageArea {
	width: 92px;
	margin-bottom: 0;
}

.myTop2 .myTop2In .list li.liType01 a .cell.imageArea img {
	width: 92px;
}

.myTop2 .myTop2In .list li.liType01 a .cell.textArea {
	padding-bottom: 30px;
}

.myTop3 .modImgBlock_txt {
	width: auto;
}

.myTop3 .modImgBlock_txt .text01 {
	margin-bottom: 20px;
}

.myTop3 .modImgBlock_txt .topBtn {
	text-align: center;
}

.myTop3 .modImgBlock_image {
	width: auto;
}

}

@media only screen and (max-width: 600px) {

.modTitleTop .TitleTopPc {
	display: none;
}

.modTitleTop .TitleTopSp {
	display: block;
}

.modTitleTop .modTitleTopIn .textWrap .textWrapIn {
	font-size: 15px;
	font-size: 1.5rem;
	height: 420px;
}

.modTitleTop .title {
	font-size: 15px;
	font-size: 1.5rem;
}

.modTitleTop .text1 {
	font-size: 15px;
	font-size: 1.5rem;
}

}

