@charset "utf-8";
@import "_setting";
@import "_mixin";

.modTitleTop{
	background:url(../img/top/main_bg1_pc.jpg) center bottom repeat-x;
	@include tabletP{
		background:none;
	}
	.TitleTopPc{
		@include sphoneP{
			display:none;
		}
	}
	.TitleTopSp{
		display:none;
		@include sphoneP{
			display:block;
		}
	}
	.textArea{
		@include tabletP{
			padding:20px;
		}
	}
	.modTitleTopIn{
		//height:580px;
		position:relative;
		max-width: 980px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 20px;
		padding-left: 20px;
		@include tabletP{
			height:auto;
			padding-right: 0;
			padding-left: 0;
		}
		.modTitleTopImg{
			position:absolute;
			right:-194px;
			bottom:0;
		}
		.textArea{
			padding-bottom: 100px;
		}
		.textWrap{
			@include tabletP{
				background:url(../img/top/main_bg1_pc.jpg) center bottom repeat-x #fff;
				background-size:auto 100%;
				overflow:hidden;

			}
			.textWrapIn{
			@include tabletP{
					height:600px;
					background:url(../img/top/main_bg2_sp.jpg) left bottom no-repeat;
					background-size:auto 100%;
					overflow:hidden;
					padding-right: 20px;
					padding-left: 20px;
				}
				@include sphoneP{
					@include fz(15);
					height:420px;
				}
			}
		}

	}
	.title{
		padding-top:103px;
		margin-bottom:20px;
		@include tabletP{
			padding-top:30px;
			margin-bottom:15px;
			overflow:hidden;
			img{
				float:right;
				width:68%;
				max-width:441px;
			}
		}
		@include sphoneP{
			@include fz(15);
		}
	}
	.text1{
		font-weight:bold;
		@include fz(24);
		margin-bottom:20px;
		@include tabletP{
			margin-bottom:0;
			clear:both;
			float:right;
			text-align:right;
		}
		@include sphoneP{
			@include fz(15);
		}
	}
	.text2{
		font-weight:bold;
		@include fz(14);
		margin-bottom:20px;
		line-height:2;
	}
}

.myTop1{
	.box01{
		background:url(../img/top/top_bg1_pc.jpg) center center no-repeat;
		background-size:cover;
		color:#fff;
		padding:65px 55px 60px;
		margin-bottom:20px;
		@include tabletP{
			background:url(../img/top/top_bg1_sp.jpg) center center no-repeat;
			background-size:cover;
			padding:20px 30px 30px;
		}
		.modTitle02{
			margin-bottom:25px;
		}
		.text01{
			@include fz(14);
			margin-bottom:30px;
		}
		.modBtn01_inline{
			display:block;
			@include fz(16);
			padding-left:30px;
			padding-right:30px;
			@include tabletP{
				width: auto;
				max-width: 382px;
				margin: 0 auto;
			}
		}
		.linklist {
			@include tabletPMin {
				display: flex;
				justify-content: space-between;
			}
			.link {
				@include tabletPMin {
					flex-grow: 1;
					&:not(:last-child) {
						margin-right: 18px;
					}
				}
				@include tabletP {
					&:not(:last-child) {
						margin-bottom: 18px;
					}
				}
			}
		}
	}
	.list{
		margin-bottom:40px;
		display: flex;
		justify-content: space-between;
		@include tabletP {
			flex-direction: column;
		}
		li {
			background: #e5f4ff;
		}
		.textArea{
			padding:25px;
			.j_match{
				margin-bottom:25px;
			}
			.inText01{
				border-bottom:1px solid #fff;
				text-align:center;
				font-weight:bold;
				padding-bottom:10px;
				margin-bottom:10px;
				@include fz(18);
				color:#00376a;
				.color01{
					display:block;
					@include fz(12);
					color:#58a1d7;
				}
			}
			.inText02{
				@include fz(14);
			}
			.modBtn01{
				@include fz(15);
				@include tabletP{
					width: auto;
					max-width:382px;
					margin:0 auto;
				}
			}
		}
		&.modColumnPc_3n{
			li{
				width: 32%;
				margin-left: 2%;
				@include tabletP{
					width: auto;
					margin-left: 0;
				}
				&:nth-child(3n+1){
					margin-left:0;
				}
				@include tabletP{
					&+li{
						margin-top:20px;
					}
				}
			}
		}
	}
}

.myTop2{
	.myTop2In{
		background:#e5f4ff;
		padding:50px 60px;
		margin-bottom:20px;
		@include tabletP{
			padding:30px 20px;
		}
		.text01{
			margin-bottom:30px;
		}
		.list{
			overflow:hidden;
			li{
				float:left;
				width:32%;
				margin-bottom:20px;
				@include tabletP{
					float:none;
					width:auto;
					word-break:break-all;
				}
				&:nth-child(3){
					clear:both;
					margin-right:2%;
					@include tabletP{
						margin-right:0;
					}
				}
				&:nth-child(5){
					float:right;
					@include tabletP{
						float:none;
					}
				}
				a{
					text-align:center;
					text-decoration:none;
					display:block;
					background:#fff;
					-moz-border-radius:7px;
					-webkit-border-radius:7px;
					border-radius:7px;
					padding:30px;
					@include tabletP{
						display:table;
						width:100%;
						padding:20px 10px;
					}
					.cell{
						@include tabletP{
							display:table-cell;
							vertical-align:middle;
							line-height:1.3;
						}
						&.imageArea{
							margin-bottom:20px;
							@include tabletP{
								width:92px;
								margin-bottom:0;
							}
							img{
								width:110px;
								@include tabletP{
									width:92px;
								}
							}
						}
						&.textArea{
							background:url(../img/top/btn_bg.png) right bottom no-repeat;
							background-size:19px auto;
							border-bottom:2px solid #00376a;
							font-weight:bold;
							text-align:center;
							@include fz(20);
							padding-bottom:30px;
							@include tabletP{
								margin-right:10px;
							}
							.inText01{
								display:block;
								@include fz(14);
							}
						}
					}
				}
				&.liType01{
					width:49%;
					@include tabletP{
						width:100%;
					}
					a{
						display:table;
						width:100%;
						.cell{
							display:table-cell;
							vertical-align:bottom;
							@include tabletP{
								vertical-align:middle;
							}
							&.imageArea{
								text-align:left;
								margin-bottom:0;
								width:140px;
								@include tabletP{
									width:92px;
									margin-bottom:0;
								}
								img{
									width:130px;
									@include tabletP{
										width:92px;
									}
								}
							}
							&.textArea{
								padding-bottom:50px;
								@include tabletP{
									padding-bottom:30px;
								}
							}
						}
					}
				}
				&:nth-child(2){
					float:right;
				}
			}

		}
	}
	.topBtn{
		text-align:center;
	}
}

.myTop3{
	.modImgBlock_txt{
		width:53%;
		@include fz(14);
		@include tabletP{
			width:auto;
		}
		.text01{
			margin-bottom:50px;
			@include tabletP{
				margin-bottom:20px;
			}
		}
		.topBtn{
			@include tabletP{
				text-align:center;
			}
		}
	}
	.modImgBlock_image{
		width:43%;
		@include tabletP{
			width:auto;
		}
	}
}

.error404_block {
	height: 50vh;
}

.error-ttl {
	font-size: 400%;
}

